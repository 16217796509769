import {
  AccessPermissions,
  ItemListResponse,
  PricingPolicyItemExceptionTypeKeys,
  UserAccessResponse,
  accessManagementService,
  amsLegacyService,
  amsV3Service
} from '../../services';
import {
  CircularProgress,
  Container,
  Grid,
  IconButton,
  TextField,
  Theme,
  Tooltip,
  Typography,
  createStyles,
  makeStyles
} from '@material-ui/core';
import {
  PricingPolicyItemCategoryResponse,
  PricingPolicyItemExceptionResponse
} from '../../services/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useCompanies, useItemCategories, useObservable } from '../../helpers/hooks';

import AMSAutocomplete from '../../helpers/ui/AMSAutocomplete/AMSAutocomplete';
import AMSButton from '../../helpers/ui/AMSButton/AMSButton';
import AMSConfirmDialog from '../../helpers/ui/AMSConfirmDialog/AMSConfirmDialog';
import AMSSnackBar from '../../helpers/ui/AMSSnackBar/AMSSnackBar';
import Category from '../../models/category';
import ListPricing from './ListPricing';
import Lookup from '../../models/lookup';
import PanToolIcon from '@material-ui/icons/PanTool';
import SearchIcon from '@material-ui/icons/Search';
import SettingsIcon from '@material-ui/icons/Settings';
import { getExtendedCompanyId } from '../../helpers/utils';
import { parseQuery } from '../../helpers/url';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      margin: 0,
      display: 'flex',
      alignItems: 'start'
    },
    overflowAuto: {
      overflow: 'auto'
    },
    title: {
      borderBottom: '1px solid #DDD',
      textAlign: 'left',
      width: '75%',
      marginLeft: 16,
      marginTop: 6
    },
    leftHeader: {
      borderBottom: '1px solid #DDD',
      textAlign: 'right',
      paddingRight: 36,
      marginTop: 6
    },
    itemHeader: {
      display: 'flex',
      overflow: 'auto',
      borderBottom: '1px solid #DDD',
      justifyContent: 'space-between',
      paddingLeft: 16
    },
    categoriesContent: {
      overflow: 'auto',
      maxHeight: '75vh'
    },
    itemWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%'
    },
    normal: {
      marginLeft: 16,
      width: 125
    },
    manuallyChanged: {
      marginLeft: 16,
      width: 125,
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #4CAF50'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '3px solid #4CAF50'
      }
    },
    manuallyChangedIcon: {
      color: '#4CAF50'
    },
    manuallyChangedLowerValue: {
      marginLeft: 16,
      width: 125,
      '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        border: '2px solid #F79803'
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '3px solid #F79803'
      }
    },
    manuallyChangedLowerValueIcon: {
      color: '#F79803'
    },
    calculatedPriceIcon: {
      padding: '2px 0px'
    },
    buttonWrapper: {
      overflow: 'auto',
      paddingLeft: 16
    },
    saveButton: {
      marginTop: 10
    }
  })
);

const PricingPolicyComponent = ({ location }: any) => {
  const currentUser: UserAccessResponse | undefined = useObservable(
    accessManagementService.user
  ).user;
  const classes = useStyles();
  const history = useHistory();

  const { categories } = useItemCategories();
  const { companies } = useCompanies();

  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [pricingPolicy, setPricingPolicy] = useState<any>({});
  const [namePlaceholder, setNamePlaceholder] = useState<string>('');
  const [coeff, setCoeff] = useState<any>({});
  const [prices, setPrices] = useState<any>({});
  const [multipliers, setMultipliers] = useState<any>({});
  const [list, setList] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [categoryFilter, setCategoryFilter] = useState<string | undefined>();

  const [itemId, setItemId] = useState<number>(-1);
  const [items, setItems] = useState<any[]>([]);
  const [itemsLoading, setItemsLoading] = useState(false);

  const [company, setCompany] = useState<Lookup>();

  const [openConfirmItemPriceDialog, setOpenConfirmItemPriceDialog] = useState(false);
  const [openConfirmItemMultiplierDialog, setOpenConfirmItemMultiplierDialog] = useState(false);
  const [confirmDialogTitle, setConfirmDialogTitle] = useState('');
  const [confirmDialogMessage, setConfirmDialogMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const { id, copyOf } = parseQuery(location.search);

  const canReadAllPricingPolicies = useMemo(
    () => accessManagementService.hasPermission(AccessPermissions.CAN_READ_ALL_PRICING_POLICIES),
    []
  );

  const tree = (categories: any[], root: number | null) => {
    var r: any[] = [],
      o: any = {};
    categories.forEach((a: any) => {
      if (o[a.id] && o[a.id].children) {
        a.children = o[a.id] && o[a.id].children;
      }
      o[a.id] = a;
      if (a.parentId === root) {
        r.push(a);
      } else {
        o[a.parentId] = o[a.parentId] || {};
        o[a.parentId].children = o[a.parentId].children || [];
        o[a.parentId].children.push(a);
      }
    });
    return r;
  };

  const reorderItems = useCallback((itemsList: ItemListResponse[], ps: any) => {
    const manuallyChangedItems = itemsList
      .filter((i: ItemListResponse) => ps[`${i.id}`])
      .sort((i1: ItemListResponse, i2: ItemListResponse) => i1.name.localeCompare(i2.name));
    const calculatedPriceItems = itemsList
      .filter((i: ItemListResponse) => !ps[`${i.id}`])
      .sort((i1: ItemListResponse, i2: ItemListResponse) => i1.name.localeCompare(i2.name));
    setItems([...manuallyChangedItems, ...calculatedPriceItems]);
  }, []);

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      if (id || copyOf) {
        const [categoriesResp, pricingCategoriesResp, pricingItemsResp, pricingPolicyResp] =
          await Promise.all([
            amsLegacyService.getCategories(),
            amsLegacyService.getPricingPolicyCategories(id || copyOf),
            amsLegacyService.getPricingPolicyItems(id || copyOf),
            amsLegacyService.getPricingPolicy(id || copyOf)
          ]);
        setCompany({
          id: pricingPolicyResp.data.companyId,
          value: `${getExtendedCompanyId(pricingPolicyResp.data?.companyId ?? 0)} ${
            pricingPolicyResp.data.companyNote
          } (${pricingPolicyResp.data.companyName})`
        });
        setData(categoriesResp.data);
        const ft = flattenTree(categoriesResp ? categoriesResp.data : []);
        setList(ft);
        if (id) {
          setPricingPolicy(pricingPolicyResp.data);
        } else {
          setNamePlaceholder(`копие на '${pricingPolicyResp.data.name}'`);
        }
        setCoeff(
          pricingCategoriesResp.data.reduce((res: any, cat: PricingPolicyItemCategoryResponse) => {
            if (!res) {
              res = {};
            }
            res[`${cat.itemCategoryId}`] = cat.multiplier;
            return res;
          }, {})
        );
        setPrices(
          pricingItemsResp.data
            .filter(
              (item: PricingPolicyItemExceptionResponse) =>
                item.type === PricingPolicyItemExceptionTypeKeys.FIXED
            )
            .reduce((res: any, item: PricingPolicyItemExceptionResponse) => {
              if (!res) {
                res = {};
              }
              res[`${item.itemId}`] = item.value;
              return res;
            }, {})
        );
        setMultipliers(
          pricingItemsResp.data
            .filter(
              (item: PricingPolicyItemExceptionResponse) =>
                item.type === PricingPolicyItemExceptionTypeKeys.MULTIPLIER
            )
            .reduce((res: any, item: PricingPolicyItemExceptionResponse) => {
              if (!res) {
                res = {};
              }
              res[`${item.itemId}`] = item.value;
              return res;
            }, {})
        );
      } else {
        if (currentUser) {
          setCompany({
            id: currentUser.companyId,
            value: `${getExtendedCompanyId(currentUser.companyId)} ${currentUser.companyNote} (${
              currentUser.companyName
            })`
          });
        }
        setData(categories);
        const ft = flattenTree(categories);
        setList(ft);
        setCoeff(
          ft.reduce((res: any, cat: Category) => {
            const one: number = 1;
            if (!res) {
              res = {};
            }
            res[`${cat.id}`] = one.toFixed(3);
            return res;
          }, {})
        );
      }
      setLoading(false);
    };
    loadInitialData();
  }, [id, copyOf, currentUser, categories, companies]);

  useEffect(() => {
    const loadItemsByCategory = async () => {
      setItemsLoading(true);
      const itemsResp = await amsV3Service.getItems(
        [],
        undefined,
        [selected.id],
        undefined,
        undefined,
        undefined,
        true
      );
      if (itemsResp) {
        reorderItems(itemsResp.data.data, prices);
      }
      setItemsLoading(false);
    };
    if (selected) {
      loadItemsByCategory();
    } // eslint-disable-next-line
  }, [selected, reorderItems]);

  useEffect(() => {
    if (categoryFilter && categoryFilter.length >= 3) {
      const filter = `(${categoryFilter
        .trim()
        .split(' ')
        .map((word) => `(?=.*${word})`)
        .join('+')})`;
      const matches: Category[] = list.filter(
        (l: any) => categoryFilter && new RegExp(filter, 'gi').test(l.name)
      );

      const getCategory = (id: number) => list.find((c: Category) => c.id === id);

      const resultList: any[] = [...matches];
      resultList.forEach((cat: any) => {
        if (!!cat.parentId) {
          const parent = getCategory(cat.parentId);
          if (!resultList.map((l) => l.id).includes(parent.id)) {
            resultList.push({ ...parent });
            if (!!parent.parentId) {
              const grandParent = getCategory(parent.parentId);
              if (!resultList.map((l) => l.id).includes(grandParent.id)) {
                resultList.push({ ...grandParent });
              }
            }
          }
        }
      });
      setData(tree(resultList, null));
    } else {
      setData(
        tree(
          list.map((l: any) => ({ ...l, children: null })),
          null
        )
      );
    }
    setCategoryFilter(categoryFilter);
  }, [categoryFilter, list]);

  const itemExceptionIds = useMemo(() => {
    const uniqueItemExceptionIds = new Set([...Object.keys(prices), ...Object.keys(multipliers)]);
    return [...uniqueItemExceptionIds];
  }, [prices, multipliers]);

  const hasInvalidData = useMemo(
    () =>
      Object.keys(prices)
        .map((key) => prices[key] === '' || isNaN(prices[key]))
        .some((v) => v) ||
      Object.keys(multipliers)
        .map((key) => multipliers[key] === '' || isNaN(multipliers[key]))
        .some((v) => v) ||
      Object.keys(coeff)
        .map((key) => coeff[key] === '' || isNaN(coeff[key]))
        .some((v) => v),
    [prices, multipliers, coeff]
  );

  const handleOnClick = async () => {
    setActionLoading(true);
    if (id) {
      const updatePricingPolicyRequest = {
        id: pricingPolicy.id,
        name: pricingPolicy.name,
        companyId: company?.id,
        itemCategories: Object.keys(coeff)
          .filter((cId) => +coeff[cId] !== 1)
          .map((cId) => ({
            pricingPolicyId: pricingPolicy.id,
            itemCategoryId: +cId,
            multiplier: coeff[cId]
          })),
        itemExceptions: itemExceptionIds.map((itemId) => {
          const price = Object.keys(prices).includes(itemId) ? +prices[itemId] : null;
          const multiplier =
            Object.keys(multipliers).includes(itemId) && !price ? +multipliers[itemId] : 1;

          return {
            pricingPolicyId: pricingPolicy.id,
            itemId: +itemId,
            type: price
              ? PricingPolicyItemExceptionTypeKeys.FIXED
              : PricingPolicyItemExceptionTypeKeys.MULTIPLIER,
            value: price?.toFixed(2) ?? multiplier?.toFixed(3)
          };
        })
      };
      const resp = await amsLegacyService.updatePricingPolicy(updatePricingPolicyRequest);
      if (resp && resp.data) {
        setOpenSnackBar(true);
      }
    } else {
      const createPricingPolicyRequest = {
        name: pricingPolicy.name,
        companyId: company?.id ?? currentUser?.companyId,
        itemCategories: Object.keys(coeff)
          .filter((cId) => +coeff[cId] !== 1)
          .map((cId) => ({
            itemCategoryId: +cId,
            multiplier: coeff[cId]
          })),
        itemExceptions: itemExceptionIds.map((itemId) => {
          const price = Object.keys(prices).includes(itemId) ? +prices[itemId] : null;
          const multiplier =
            Object.keys(multipliers).includes(itemId) && !price ? +multipliers[itemId] : 1;
          return {
            pricingPolicyId: pricingPolicy.id,
            itemId: +itemId,
            type: price
              ? PricingPolicyItemExceptionTypeKeys.FIXED
              : PricingPolicyItemExceptionTypeKeys.MULTIPLIER,
            value: price?.toFixed(2) ?? multiplier?.toFixed(3)
          };
        })
      };
      const resp = await amsLegacyService.createPricingPolicy(createPricingPolicyRequest);
      if (resp && resp.data) {
        history.push(`/pricing-policy?id=${resp.data.id}`);
        setOpenSnackBar(true);
      }
    }
    setActionLoading(false);
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item lg={12}>
          <Grid container spacing={1}>
            <Grid item lg={3}>
              <TextField
                label="Име"
                variant="outlined"
                fullWidth
                required
                margin="dense"
                defaultValue={pricingPolicy.name}
                onChange={(event) => {
                  setPricingPolicy({ ...pricingPolicy, name: event.target.value });
                }}
                placeholder={namePlaceholder}
              />
            </Grid>
            {canReadAllPricingPolicies && (
              <Grid item lg={4} md={12} sm={12}>
                <AMSAutocomplete
                  label="Компания"
                  options={companies.map((c) => ({
                    id: c.id,
                    value: `${getExtendedCompanyId(c.id)} ${c.note} (${c.name})`
                  }))}
                  value={company}
                  onChange={(value: Lookup | undefined) => setCompany(value)}
                  minChar={0}
                  required
                />
              </Grid>
            )}
            <Grid item lg={1} className={classes.buttonWrapper}>
              <AMSButton
                text="Запиши"
                variant="contained"
                color="primary"
                onClick={handleOnClick}
                loading={actionLoading}
                disabled={actionLoading || !pricingPolicy.name || hasInvalidData}
                className={classes.saveButton}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={12} className={classes.overflowAuto}>
          <Grid container>
            <Grid item lg={4}>
              <TextField
                label="Търсене на категория"
                variant="outlined"
                fullWidth
                margin="dense"
                defaultValue={categoryFilter}
                onChange={(event) => {
                  setCategoryFilter(event.target.value);
                }}
                placeholder="Минимум 3 символа"
                InputProps={{
                  startAdornment: <SearchIcon />,
                  endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} className={classes.overflowAuto}>
          <Typography className={classes.leftHeader}>{`Коефициент`}</Typography>
        </Grid>
        <Grid item lg={8} className={classes.itemHeader}>
          <Typography>{`Мултипликатор`}</Typography>
          <Typography>{`Цена`}</Typography>
          <div style={{ width: '75%' }}></div>
        </Grid>
        <Grid item lg={4} className={classes.categoriesContent}>
          {data && (
            <ListPricing
              data={data}
              className="full-width"
              selected={selected}
              setSelected={setSelected}
              coeff={coeff}
              setCoeff={setCoeff}
            />
          )}
        </Grid>
        <Grid item lg={8}>
          {itemsLoading ? (
            <CircularProgress />
          ) : (
            items.map((item: any) => (
              <div key={`item-${item.id}`} className={classes.itemWrapper}>
                <TextField
                  className={
                    multipliers[item.id] !== undefined &&
                    +multipliers[item.id] !== +coeff[selected.id]
                      ? +coeff[selected.id] < +multipliers[item.id]
                        ? classes.manuallyChanged
                        : classes.manuallyChangedLowerValue
                      : classes.normal
                  }
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  required
                  id={`multipliers-${item.id}`}
                  name={`multipliers-${item.id}`}
                  value={
                    multipliers[item.id] !== undefined ? multipliers[item.id] : +coeff[selected.id]
                  }
                  type="number"
                  onChange={(event) => {
                    if (event.target.value) {
                      setMultipliers({
                        ...multipliers,
                        [item.id]: +event.target.value
                      });
                      const tempPrices = { ...prices };
                      delete tempPrices[item.id];
                      setPrices(tempPrices);
                    } else {
                      setMultipliers({
                        ...multipliers,
                        [item.id]: ''
                      });
                    }
                  }}
                  onBlur={(event) => {
                    if (event.target.value && multipliers[item.id]) {
                      setMultipliers({
                        ...multipliers,
                        [item.id]: (+multipliers[item.id]).toFixed(3)
                      });
                      reorderItems(items, {
                        ...multipliers,
                        [item.id]: (+multipliers[item.id]).toFixed(3)
                      });
                    } else {
                      reorderItems(items, multipliers);
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: '2px 8px 2px 4px',
                      textAlign: 'right'
                    }
                  }}
                  InputProps={{
                    startAdornment:
                      multipliers[item.id] !== undefined &&
                      +multipliers[item.id] !== +coeff[selected.id] ? (
                        <Tooltip title={`Конфигурирана стойност : ${+coeff[selected.id]}`}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setConfirmDialogTitle('Премахване на запис');
                              setConfirmDialogMessage(
                                `Наистина ли искате да премахнете ръчно въведения запис?\nКонфигурирана стойност : ${+coeff[
                                  selected.id
                                ]}`
                              );
                              setOpenConfirmItemMultiplierDialog(true);
                              setItemId(item.id);
                            }}
                          >
                            <PanToolIcon
                              className={
                                +coeff[selected.id] < +multipliers[item.id]
                                  ? classes.manuallyChangedIcon
                                  : classes.manuallyChangedLowerValueIcon
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton size="small" disabled>
                          <SettingsIcon color="primary" className={classes.calculatedPriceIcon} />
                        </IconButton>
                      ),
                    style: {
                      paddingLeft: !!multipliers[item.id] ? 1 : 3
                    }
                  }}
                  size="small"
                />
                <TextField
                  className={
                    prices[item.id] !== undefined
                      ? multipliers[item.id] !== undefined
                        ? +multipliers[item.id] * +item.lastKnownPrice <= prices[item.id]
                          ? classes.manuallyChanged
                          : classes.manuallyChangedLowerValue
                        : +coeff[selected.id] * +item.lastKnownPrice <= prices[item.id]
                        ? classes.manuallyChanged
                        : classes.manuallyChangedLowerValue
                      : classes.normal
                  }
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  required
                  id={`prices-${item.id}`}
                  name={`prices-${item.id}`}
                  value={
                    prices[item.id] !== undefined
                      ? prices[item.id]
                      : multipliers[item.id] !== undefined
                      ? (multipliers[item.id] * +item.lastKnownPrice).toFixed(2)
                      : (+coeff[selected.id] * +item.lastKnownPrice).toFixed(2)
                  }
                  type="number"
                  onChange={(event) => {
                    if (event.target.value) {
                      setPrices({
                        ...prices,
                        [item.id]: +event.target.value
                      });
                      const tempMultipliers = { ...multipliers };
                      delete tempMultipliers[item.id];
                      setMultipliers(tempMultipliers);
                    } else {
                      setPrices({
                        ...prices,
                        [item.id]: ''
                      });
                    }
                  }}
                  onBlur={(event) => {
                    if (event.target.value && prices[item.id]) {
                      setPrices({
                        ...prices,
                        [item.id]: (+prices[item.id]).toFixed(2)
                      });
                      reorderItems(items, {
                        ...prices,
                        [item.id]: (+prices[item.id]).toFixed(2)
                      });
                    } else {
                      reorderItems(items, prices);
                    }
                  }}
                  inputProps={{
                    style: {
                      padding: '2px 8px 2px 4px',
                      textAlign: 'right'
                    }
                  }}
                  InputProps={{
                    startAdornment:
                      prices[item.id] !== undefined ? (
                        <Tooltip
                          title={`Калкулирана стойност : ${(multipliers[item.id] !== undefined
                            ? +multipliers[item.id] * +item.lastKnownPrice
                            : +coeff[selected.id] * +item.lastKnownPrice
                          ).toFixed(2)}`}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              setConfirmDialogTitle('Премахване на запис');
                              setConfirmDialogMessage(
                                `Наистина ли искате да премахнете ръчно въведения запис?\nКалкулирана стойност : ${(
                                  +coeff[selected.id] * +item.lastKnownPrice
                                ).toFixed(2)}`
                              );
                              setOpenConfirmItemPriceDialog(true);
                              setItemId(item.id);
                            }}
                          >
                            <PanToolIcon
                              className={
                                +coeff[selected.id] * +item.lastKnownPrice <= prices[item.id]
                                  ? classes.manuallyChangedIcon
                                  : classes.manuallyChangedLowerValueIcon
                              }
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton size="small" disabled>
                          <SettingsIcon color="primary" className={classes.calculatedPriceIcon} />
                        </IconButton>
                      ),
                    style: {
                      paddingLeft: !!prices[item.id] ? 1 : 3
                    }
                  }}
                  size="small"
                />
                <Typography
                  key={`typography-${item.id}`}
                  className={classes.title}
                >{`${item.name}`}</Typography>
              </div>
            ))
          )}
        </Grid>
      </Grid>
      <AMSConfirmDialog
        open={openConfirmItemPriceDialog}
        onConfirm={() => {
          setOpenConfirmItemPriceDialog(false);
          const newPrices = { ...prices };
          delete newPrices[itemId];
          setPrices({ ...newPrices });
          reorderItems(items, newPrices);
        }}
        onClose={() => setOpenConfirmItemPriceDialog(false)}
        title={confirmDialogTitle}
        message={confirmDialogMessage}
      />
      <AMSConfirmDialog
        open={openConfirmItemMultiplierDialog}
        onConfirm={() => {
          setOpenConfirmItemMultiplierDialog(false);
          const newMultipliers = { ...multipliers };
          delete newMultipliers[itemId];
          setMultipliers({ ...newMultipliers });
        }}
        onClose={() => setOpenConfirmItemMultiplierDialog(false)}
        title={confirmDialogTitle}
        message={confirmDialogMessage}
      />
      <AMSSnackBar open={openSnackBar} onClose={() => setOpenSnackBar(false)} />
    </Container>
  );
};

const flattenTree = (tree: any, fieldToExtract?: string) =>
  tree.reduce((res: any[], item: any, index: number) => {
    if (!res) {
      res = [];
    }
    if (item.children && item.children.length) {
      const flat = flattenTree(item.children, fieldToExtract);
      res = [...res, ...flat];
    }
    res.push(fieldToExtract ? item[fieldToExtract] : { ...item });
    return res;
  }, []);

export default PricingPolicyComponent;
