import {
  DeliveryItemResponse,
  DeliveryResponse,
  ItemListResponse,
  amsV3Service
} from '../../../../services';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AMSLink from '../../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../../helpers/ui/AMSTable/AMSTable';
import AddDeliveryItemDialog from './Dialog/AddDeliveryItemDialog';
import { CreateDeliveryItemRequest } from '../../../../services';
import Lookup from '../../../../models/lookup';
import { Paper } from '@material-ui/core';
import { findCountry } from '../../../../helpers/country-helper';
import { useFlag } from '../../../../helpers/hooks';

interface DeliveryItemsPanelProps {
  delivery: DeliveryResponse | null;
  supplier: Lookup | null;
  warehouse: Lookup | null;
  deliveryItems: any[];
  setDeliveryItems?: (items: any[]) => void;
  loading: boolean;
}

const DeliveryItemsPanel = ({
  delivery,
  supplier,
  warehouse,
  deliveryItems,
  setDeliveryItems,
  loading
}: DeliveryItemsPanelProps) => {
  const [openDialog, setOpenDialog] = useFlag(false);
  const [actionLoading, setActionLoading] = useFlag(false);

  const handleAdd = async (newOrderItem: CreateDeliveryItemRequest) => {
    setActionLoading(true);
    setDeliveryItems!([...deliveryItems, newOrderItem]);
    setActionLoading(false);
  };

  const handleDelete = async (deleteOrderItem: any) => {
    setActionLoading(true);
    const tempItems = [...deliveryItems];
    tempItems.splice(deleteOrderItem.tableData.id, 1);
    setDeliveryItems!(tempItems);
    setActionLoading(false);
  };

  const [items, setItems] = useState<ItemListResponse[]>([]);

  const loadData = useCallback(async () => {
    if (deliveryItems) {
      const itemsIds = deliveryItems.map((di: any) => di.itemId);
      const itemsResp = await amsV3Service.getItems(
        itemsIds,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      );
      setItems(itemsResp.data.data);
    }
  }, [deliveryItems]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const itemLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item;
        return res;
      }, {}),
    [items]
  );

  return (
    <>
      <AMSTable
        title="Списък с артикули"
        columns={[
          {
            title: 'Име',
            field: 'name',
            cellStyle: { width: '25%' },
            render: (deliveryItem: DeliveryItemResponse) => (
              <AMSLink href={`/item?id=${deliveryItem.itemId}`}>
                {deliveryItem.itemId in itemLookup ? itemLookup[deliveryItem.itemId].name : '-'}
              </AMSLink>
            )
          },
          {
            title: 'Държава',
            field: 'country',
            cellStyle: { width: '9%' },
            render: (row: DeliveryItemResponse) => findCountry(row.country)?.name
          },
          {
            title: 'Срок на годност',
            field: 'expirationDate',
            cellStyle: { width: '10%' }
          },
          {
            title: 'Партида',
            field: 'batchNumber',
            cellStyle: { width: '10%' }
          },
          {
            title: 'Брой',
            field: 'quantity',
            cellStyle: { width: '8%' },
            render: (row: DeliveryItemResponse) => row.quantity
          },
          {
            title: 'Ед. цена',
            field: delivery ? 'priceWithoutVat' : 'price',
            cellStyle: { width: '10%' },
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          },
          {
            title: 'Общо (без ДДС)',
            field: 'totalWithoutVat',
            cellStyle: { width: '10%' },
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          },
          {
            title: 'Общо (с ДДС)',
            field: 'totalIncludingVat',
            cellStyle: { width: '10%' },
            type: 'currency',
            currencySetting: {
              locale: 'bg',
              currencyCode: 'bgn',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }
          }
        ]}
        paging={false}
        inlineActions={false}
        components={{
          Container: (props: any) => <Paper {...props} elevation={0} className="padding-0" />
        }}
        data={deliveryItems}
        onAdd={
          !delivery && supplier && warehouse
            ? () => {
                setOpenDialog(true);
              }
            : undefined
        }
        onDelete={!delivery ? handleDelete : undefined}
        isLoading={loading || actionLoading}
        overflowY="scroll"
        minBodyHeight="45vh"
        maxBodyHeight="45vh"
        addRowPosition="first"
      />
      <AddDeliveryItemDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        supplier={supplier}
        onSave={(orderItem: any, addNew: boolean) => {
          if (!addNew) {
            setOpenDialog(false);
          }
          handleAdd(orderItem);
        }}
      />
    </>
  );
};

export default DeliveryItemsPanel;
