import {
  AMSAsyncAutocomplete,
  AMSExternalItemsAutocomplete,
  AMSTextField
} from '../../../../../helpers/ui';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  ItemResponse,
  PartnerItemConfigurationResponse,
  SavePartnerItemConfigurationRequest,
  amsV3Service
} from '../../../../../services';
import Lookup, { itemToLookup } from '../../../../../models/lookup';
import React, { useEffect, useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold'
  },
  imageWrapper: {
    width: '100%',
    height: 256,
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    width: 256,
    height: 256
  },
  label: {
    padding: 6
  },
  button: {
    marginTop: 8
  },
  addNewCheckboxWrapper: {
    paddingTop: 8
  }
}));

export interface EditPartnerItemConfigurationDialogProps {
  partnerItemConfiguration?: PartnerItemConfigurationResponse;
  partnerId: number;
  partnerIntegrationType: number;
  internalItem: Lookup;
  open: boolean;
  onClose: () => void;
  onSave: (request: any, addNew: boolean) => void;
}

const EditPartnerItemConfigurationDialog = ({
  partnerId,
  partnerIntegrationType,
  partnerItemConfiguration,
  internalItem,
  open,
  onClose,
  onSave
}: EditPartnerItemConfigurationDialogProps) => {
  const classes = useStyles();
  const [min, setMin] = useState<string | null>(partnerItemConfiguration?.min ?? null);
  const [max, setMax] = useState<string | null>(partnerItemConfiguration?.max ?? null);
  const [item, setItem] = useState<Lookup | null>(null);
  const [partnerItem, setPartnerItem] = useState<Lookup | null>(null);
  const [addNew, setAddNew] = useState<boolean>(false);

  const handleAddItem = async () => {
    if (partnerId) {
      const newItem: SavePartnerItemConfigurationRequest = {
        externalItemUniqueKey: partnerItem!.id,
        externalItemName: partnerItem!.value,
        externalItemArtNo: partnerItem!.selectedView,
        itemId: item!.id,
        min: min && min.length > 0 ? min : undefined,
        max: max && max.length > 0 ? max : undefined
      };
      onSave(newItem, addNew);
      setItem(null);
      setPartnerItem(null);
      setMin(null);
      setMax(null);
    }
  };

  useEffect(() => {
    if (partnerItemConfiguration) {
      setPartnerItem({
        id: partnerItemConfiguration!.externalItemUniqueKey,
        value: partnerItemConfiguration!.externalItemName,
        selectedView: partnerItemConfiguration!.externalItemArtNo
      });
      setMin(`${partnerItemConfiguration?.min ? +partnerItemConfiguration.min : ''}`);
      setMax(`${partnerItemConfiguration?.max ? +partnerItemConfiguration.max : ''}`);
    } else {
      setPartnerItem(null);
      setMin(null);
      setMax(null);
    }
    if (internalItem) {
      setItem(internalItem);
    } else {
      setItem(null);
    }
  }, [partnerItemConfiguration, internalItem]);

  const minValue = useMemo(() => (min && min.length > 0 ? +min : 0), [min]);
  const maxValue = useMemo(() => (max && max.length > 0 ? +max : 0), [max]);
  const isValid = useMemo(
    () => partnerItem && item && minValue >= 0 && maxValue >= 0 && minValue <= maxValue,
    [partnerItem, item, minValue, maxValue]
  );
  const label = useMemo(
    () =>
      partnerIntegrationType === 1
        ? 'Barsy артикул'
        : partnerIntegrationType === 2
        ? 'I-Cash артикул'
        : partnerIntegrationType === 3
        ? 'Coshera Retail артикул'
        : partnerIntegrationType === 4
        ? 'Mistral артикул'
        : 'Артикул',
    [partnerIntegrationType]
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        <div className={classes.title}>Редакция на мапинг</div>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Grid container spacing={1}>
              <Grid item lg={9} sm={12}>
                <AMSExternalItemsAutocomplete
                  label={label}
                  partnerId={partnerId}
                  value={partnerItem}
                  onChange={async (value: Lookup | null) => {
                    setPartnerItem(value);
                    if (value) {
                      const resp = await amsV3Service.getItems(
                        [value?.id],
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        true
                      );
                      if (resp?.data?.size > 0) {
                        setItem({
                          id: resp.data.data[0].id,
                          value: `${resp.data.data[0].artNo} ${resp.data.data[0].name}`
                        });
                      }
                    }
                  }}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSTextField
                  label="Минимум"
                  value={min ?? ''}
                  onChange={(value) => setMin(value ?? '')}
                  error={minValue > maxValue}
                />
              </Grid>
              <Grid item lg={9} sm={12}>
                <AMSAsyncAutocomplete
                  label="Артикул"
                  value={item}
                  onChange={(item: ItemResponse | null) => {
                    if (item) {
                      setItem(itemToLookup(item));
                    } else {
                      setItem(null);
                    }
                  }}
                  getItemLabel={(item: any) => `${item.artNo} ${item.name}`}
                  required
                />
              </Grid>
              <Grid item lg={3} sm={12}>
                <AMSTextField
                  label="Максимум"
                  value={max ?? ''}
                  onChange={(value) => setMax(value ?? '')}
                  error={minValue > maxValue}
                  required={!!min}
                />
              </Grid>
              <Grid item lg={12} sm={12}>
                <Typography
                  style={{
                    visibility: minValue <= maxValue ? 'hidden' : undefined,
                    color: '#FD9728'
                  }}
                  align="right"
                >
                  Минималната стойност трябва да е по-малка от максималната
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div className={classes.addNewCheckboxWrapper}>
          <FormControlLabel
            className={classes.label}
            control={<Checkbox checked={addNew} onChange={() => setAddNew(!addNew)} />}
            label="Добави нов след запис"
            labelPlacement="end"
          />
        </div>
        <Button onClick={onClose} color="secondary" variant="contained">
          Отказ
        </Button>
        <Button onClick={handleAddItem} color="primary" variant="contained" disabled={!isValid}>
          Добре
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditPartnerItemConfigurationDialog;
