import {
  AccessPermissions,
  ItemListResponse,
  ItemResponse,
  SimilarItemAssociationResponse,
  accessManagementService,
  amsV3Service
} from '../../../services';
import { IconButton, TableCell, TableRow, Typography, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AMSAsyncAutocomplete from '../../../helpers/ui/AMSAsyncAutocomplete/AMSAsyncAutocomplete';
import AMSLink from '../../../helpers/ui/AMSLink/AMSLink';
import AMSTable from '../../../helpers/ui/AMSTable/AMSTable';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'flex'
  }
}));

interface SimilarItemsContainerProps {
  id: number;
}

const SimilarItemsContainer = (props: SimilarItemsContainerProps) => {
  const { id } = props;
  const classes = useStyles();

  const [item, setItem] = useState<any>(null);
  const [similarItems, setSimilarItems] = useState<SimilarItemAssociationResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<ItemListResponse[]>([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    if (id) {
      const resp = await amsV3Service.getSimilarItems(id);
      setSimilarItems(resp.data);
      const similarItemsIds = resp.data.map(
        (si: SimilarItemAssociationResponse) => si.similarItemId
      );
      const itemsResp = await amsV3Service.getItems(
        similarItemsIds,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true
      );
      setItems(itemsResp.data.data);
    } else {
      setSimilarItems([]);
    }
    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const itemArtNoLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.artNo;
        return res;
      }, {}),
    [items]
  );

  const itemNamesLookup = useMemo(
    () =>
      items.reduce((res: any, item: ItemListResponse) => {
        res[item.id] = item.name;
        return res;
      }, {}),
    [items]
  );

  const columns = useMemo(
    () => [
      {
        title: 'Арт №',
        field: 'similarItemId',
        lookup: itemArtNoLookup,
        render: (l: SimilarItemAssociationResponse) => {
          const itemArtNo = itemArtNoLookup[l.similarItemId];
          return <AMSLink href={`/item?id=${l.itemId}`}>{itemArtNo}</AMSLink>;
        },
        cellStyle: { width: '8%' }
      },
      {
        title: 'Име',
        field: 'similarItemId',
        lookup: itemNamesLookup,
        render: (s: SimilarItemAssociationResponse) => {
          const itemName = itemNamesLookup[s.similarItemId];
          return <AMSLink href={`/item?id=${s.itemId}`}>{itemName}</AMSLink>;
        },
        cellStyle: { width: '92%' }
      }
    ],
    [itemArtNoLookup, itemNamesLookup]
  );

  const disabled = useMemo(
    () =>
      !accessManagementService.hasPermission(AccessPermissions.CAN_CREATE_ITEM) &&
      !accessManagementService.hasPermission(AccessPermissions.CAN_UPDATE_ITEM),
    []
  );

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <AMSTable
          title="Аналози"
          columns={columns}
          isLoading={loading}
          data={similarItems}
          components={{
            EditRow: (props: any) => {
              return (
                <TableRow>
                  <TableCell colSpan={2}>
                    {props.mode === 'add' ? (
                      <AMSAsyncAutocomplete
                        label="Артикул"
                        onChange={(item: ItemResponse | null) => setItem(item)}
                        value={item}
                        minChar={3}
                      />
                    ) : (
                      <Typography component="h1" variant="h6">
                        Наистина ли искате да изтриете този запис?
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <div className={classes.flex}>
                      <IconButton
                        onClick={() =>
                          props.onEditingApproved(
                            props.mode,
                            props.mode === 'add' ? item : props.data,
                            props.mode === 'add' ? item : props.data
                          )
                        }
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton onClick={() => props.onEditingCanceled(props.mode, props.data)}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            }
          }}
          inlineActions={true}
          pageSize={8}
          onAdd={
            !disabled
              ? async (similarItem: ItemResponse) => {
                  setLoading(true);
                  const resp = await amsV3Service.addSimilarItem(id, similarItem.id);
                  if (resp?.data) {
                    loadData();
                  }
                  setLoading(false);
                }
              : undefined
          }
          onDelete={
            !disabled
              ? async (similarItem: any) => {
                  if (similarItem) {
                    setLoading(true);
                    await amsV3Service.deleteSimilarItem(id, similarItem.similarItemId);
                    const data = [...similarItems];
                    data.splice(similarItem.tableData.id, 1);
                    setSimilarItems(data);
                    setLoading(false);
                  }
                }
              : undefined
          }
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default SimilarItemsContainer;
